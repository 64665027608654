/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pickadate@3.5.6/lib/compressed/themes/classic.min.css
 * - /npm/pickadate@3.5.6/lib/compressed/themes/classic.date.min.css
 * - /npm/bootstrap@4.1.3/dist/css/bootstrap.min.css
 * - /npm/animate.css@3.5.2/animate.min.css
 * - /npm/hover.css@2.1.1/css/hover-min.min.css
 * - /npm/cookieconsent@3.0.4/build/cookieconsent.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.theme.default.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
